import { Box } from '@material-ui/core'
import CustomCalenderTimePicker from './CustomDateTimePicker'

import './Pickers.css'

const NativeDateTimePicker = ({
    handleChange,
    children,
    value,
    minDate = '',
    maxDate = '',
    disabled = false,
    disabledTimeSlots = [],
    customTimeControl = false,
    todayBtnLabel = '',
    clearBtnLabel = '',
    pickerStyles = {},
}) => {
    return (
        <Box flex="1" className="textfield">
            {children}
            {customTimeControl ? (
                <CustomCalenderTimePicker
                    todayBtnLabel={todayBtnLabel}
                    clearBtnLabel={clearBtnLabel}
                    disabledTimeSlots={disabledTimeSlots}
                    onChange={(event) => {
                        handleChange(event)
                    }}
                />
            ) : (
                <input
                    className="picker"
                    type="datetime-local"
                    value={value}
                    onChange={(event) => {
                        handleChange(event.target.value)
                    }}
                    min={minDate}
                    max={maxDate}
                    disabled={disabled}
                    style={pickerStyles}
                ></input>
            )}
        </Box>
    )
}

export default NativeDateTimePicker
