import { Popover } from "@material-ui/core";
import moment from "moment";
import { ArrowIcon } from "packages/eid-icons";
import React, { useEffect, useState } from "react";
import {
  getDatesInMonthDisplay,
  getMonth,
  getMonthSet,
  getToday,
  getYear,
  months,
} from "./helper";
import Month from "./Month";
import "./styleSheets/calendar.css";
import Times from "./Times";
import Years from "./Years";

const CustomCalenderTimePicker = ({disabledTimeSlots,todayBtnLabel,clearBtnLabel, onChange}) => {
  const [showYears, setShowYears] = useState(false);
  const [controlsDates, setControlsDates] = useState({});
  const [datesInMonth, setDatesInMonth] = useState([]);
  const [select, setSelect] = useState(getToday());
  const [slots, setSlots] = useState([])

  const monthDatesSeter = (date) => {
    const dates = getDatesInMonthDisplay(getMonth(date) + 1, getYear(date));
    setDatesInMonth([...dates]);
  };
  const controlsDatesSeter = (dates) => {
    dates.map((i) => i.currentMonth && setControlsDates(getMonthSet(i.date)));
  };

  useEffect(() => {
    if(disabledTimeSlots?.length > 0){
      const tempDisabledSlots = disabledTimeSlots
      .map((item) => {
        return (
          item?.slots?.length &&
          item?.slots?.map((slot) => {
            return [
              moment(
                `${item.date} ${slot.split("-")[0]}`,
                "DD-MM-yyyy hh:mm"
              ).toDate(),
              moment(
                `${item.date} ${slot.split("-")[1]}`,
                "DD-MM-yyyy hh:mm"
              ).toDate(),
            ];
          })
        );
      })
      .flat(1);

      setSlots(tempDisabledSlots)
    }
    
  }, [disabledTimeSlots]);

  useEffect(() => {
    if (select) {
      monthDatesSeter(select);
      onChange && onChange(select);
    }
  }, [select, onChange]);

  useEffect(() => {
    controlsDatesSeter(datesInMonth);
  }, [datesInMonth]);


  const onToggleYears = () => setShowYears((s) => !s);
  const onChangeMonth = (date) => monthDatesSeter(date);
  const nextMonth = () => monthDatesSeter(controlsDates.next);
  const prevMonth = () => monthDatesSeter(controlsDates.prev);
  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }
  return (
    <div className="dateinput">
      <input className="picker" value={moment(select)?.format()} onClick={openMenu} />
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className="calendar-main">
          <div className={`calendar-container show`}>
            <div className="calendar-divder">
              <div className="calendar-header">
                <button onClick={onToggleYears} className="calendar-button">
                  {months[getMonth(controlsDates.current)] + " "}{" "}
                  {getYear(controlsDates.current)}
                  <ArrowIcon direction={'down'} />
                </button>
                <div className="calendar-arrowBtn">
                  <button onClick={nextMonth}>
                    <ArrowIcon direction={'up'} />
                  </button>
                  <button onClick={prevMonth}>
                    <ArrowIcon direction={'down'} />
                  </button>
                </div>
              </div>
              {showYears ? (
                <Years
                  select={controlsDates.current}
                  onChange={(e) => {
                    onChangeMonth(e);
                    onToggleYears();
                  }}
                />
              ) : (
                <Month
                  onChange={setSelect}
                  datesInMonth={datesInMonth}
                  select={select}
                />
              )}
              <div className="footer-button">
                <button onClick={() => setSelect("")}>{clearBtnLabel}</button>
                <button onClick={() => setSelect(getToday())}>{todayBtnLabel}</button>
              </div>
            </div>
            <Times
              select={select}
              disabledSlots={slots}
              onChange={setSelect}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default CustomCalenderTimePicker;
