import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import Item from './Item'

const Title = ({ children, style = {}, ...rest }) => {
    if (typeof children === 'object') return <>{children}</>

    return (
        <Item>
            <Typography
                variant="h6"
                style={{
                    color: '#307fc1',
                    fontSize: '20px',
                    fontWeight: 200,
                    ...style,
                }}
                {...rest}
            >
                {children}
            </Typography>
        </Item>
    )
}

Title.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
        .isRequired,
}

export default Title
