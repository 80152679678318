import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import { useIsSmallScreen } from '../../core'
import FullScreenLoader from './FullScreenLoader'

const useLayoutStyles = makeStyles((theme) => ({
    fluidContainer: ({ rootStyles }) => ({
        position: 'absolute',
        ...theme.mixins.headerTop,
        right: 0,
        left: 0,
        bottom: 0,
        padding: 0,
        overflowY: 'auto',
        ...rootStyles,
    }),
    xlContainer: {
        padding: 0,
        display: 'flex',
    },
    globalMenuContainer: (props) => ({
        minWidth: '80px',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1200,
        ...props.rootMenuStyles,
    }),
    contentContainer: (props) => ({
        width: '100%',
        ...(!props.isSmallScreen
            ? {
                  marginLeft: '80px',
                  paddingRight: '16px',
                  paddingBottom: '25px',
              }
            : {
                  height: '100%',
                  position: 'relative',
                  marginBottom: '20px',
              }),
        ...props.contentContainerStyles,
    }),
}))

const Layout = ({
    header,
    subHeader,
    globalMenu,
    loading,
    rootMenuStyles,
    rootStyles,
    contentContainerStyles,
    children,
    scrollToTopComponent,
}) => {
    const isSmallScreen = useIsSmallScreen()
    const layoutClasses = useLayoutStyles({
        rootStyles,
        rootMenuStyles,
        contentContainerStyles,
        isSmallScreen,
    })

    return (
        <Container maxWidth={false} className={layoutClasses.fluidContainer}>
            {isSmallScreen && loading && <FullScreenLoader />}

            {header}
            <Container maxWidth="xl" className={layoutClasses.xlContainer}>
                {subHeader && subHeader}

                <div className={layoutClasses.globalMenuContainer}>
                    {globalMenu}
                </div>

                <div className={layoutClasses.contentContainer}>{children}</div>
            </Container>
            {scrollToTopComponent}
        </Container>
    )
}

export default Layout
